.modal{
  &.modal-grid-reports-event-log {
    .modal-dialog {
      min-width: 700px;
      max-width: 700px;
      max-height: calc(100vh - 60px);
      min-height: 500px;
    }
  }
}

.g-many-text-cell {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  &--count-3 {
    -webkit-line-clamp: 3;
  }
}

.ag-body-vertical-scroll-viewport {
  scrollbar-color: var(--scroll) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scroll) !important;
    border-radius: 5px !important;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-active) !important;
    cursor: pointer !important;
  }
}