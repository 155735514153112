@font-face {
  font-family: "font-icons-auth";
  src: url("../assets/fonts/FontIcons/font-icons-auth.eot");
  src: url("../assets/fonts/FontIcons/font-icons-auth.eot") format("embedded-opentype"),
  url("../assets/fonts/FontIcons/font-icons-auth.ttf") format("truetype"),
  url("../assets/fonts/FontIcons/font-icons-auth.woff") format("woff"),
  url("../assets/fonts/FontIcons/font-icons-auth.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.font-icons-auth {
  font-family: "font-icons-auth", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  display: inline-block;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-auth_password:before {
  content: "\e963";
  color: #919ca9;
}
.icon-auth_user:before {
  content: "\e964";
  color: #919ca9;
}
.icon-settings_notifications:before {
  content: "\e961";
  color: #919ca9;
}
.icon-update_db:before {
  content: "\e962";
  color: var(--main-icon);
}
.icon-clear:before {
  content: "\e960";
  color: #919ca9;
}
.icon-exclam_point:before {
  content: "\e95e";
  color: #52a6f6;
}
.icon-str_up:before {
  content: "\e95f";
  color: #95a0ac;
}
.icon-context_menu:before {
  content: "\e95d";
  color: #919ca9;
}
.icon-plus:before {
  content: "\e95c";
  color: #919ca9;
}
.icon-nocheck:before {
  content: "\e94e";
  color: #919ca9;
}
.icon-sort_asc:before {
  content: "\e94f";
  color: #919ca9;
}
.icon-sort_desc:before {
  content: "\e950";
  color: #919ca9;
}
.icon-menu_collapse_grey:before {
  content: "\e951";
  color: #95a0ac;
}
.icon-menu_open_grey:before {
  content: "\e952";
  color: #95a0ac;
}
.icon-sort_grid_desc .path1:before {
  content: "\e953";
  color: rgb(149, 160, 172);
}
.icon-sort_grid_desc .path2:before {
  content: "\e954";
  margin-left: -0.587890625em;
  color: rgb(82, 165, 246);
}
.icon-sort_grid_asc .path1:before {
  content: "\e955";
  color: rgb(149, 160, 172);
}
.icon-sort_grid_asc .path2:before {
  content: "\e956";
  margin-left: -0.587890625em;
  color: rgb(82, 165, 246);
}
.icon-check .path1:before {
  content: "\e957";
  color: rgb(82, 165, 246);
}
.icon-check .path2:before {
  content: "\e958";
  margin-left: -0.998046875em;
  color: rgb(255, 255, 255);
}
.icon-check_not_full:before {
  content: "\e959";
  color: var(--main);
}
.icon-radio_check:before {
  content: "\e95a";
  color: var(--main);
}
.icon-radio_nocheck:before {
  content: "\e95b";
  color: var(--main);
}
.icon-filter:before {
  content: "\e918";
  color: #919ca9;
}
.icon-switch_off:before {
  content: "\e919";
  color: #919ca9;
}
.icon-switch_on .path1:before {
  content: "\e91a";
  color: rgb(82, 165, 246);
}
.icon-switch_on .path2:before {
  content: "\e91b";
  margin-left: -1.4990234375em;
  color: rgb(255, 255, 255);
}
.icon-close_2:before {
  content: "\e91c";
  color: #919ca9;
}
.icon-error_info .path1:before {
  content: "\e934";
  color: rgb(211, 103, 70);
}
.icon-error_info .path2:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(240, 241, 242);
}
.icon-calendar_date:before {
  content: "\e936";
  color: #919ca9;
}
.icon-calendar_clear:before {
  content: "\e937";
  color: #919ca9;
}
.icon-calendar_time:before {
  content: "\e938";
  color: #919ca9;
}
.icon-calendar_date_checked .path1:before {
  content: "\e939";
  color: rgb(221, 235, 248);
  opacity: 0.8;
}
.icon-calendar_date_checked .path2:before {
  content: "\e93a";
  margin-left: -1.2802734375em;
  color: rgb(192, 220, 247);
  opacity: 0.8;
}
.icon-calendar_date_checked .path3:before {
  content: "\e93b";
  margin-left: -1.2802734375em;
  color: rgb(151, 197, 242);
  opacity: 0.8;
}
.icon-calendar_date_checked .path4:before {
  content: "\e93c";
  margin-left: -1.2802734375em;
  color: rgb(136, 188, 239);
  opacity: 0.8;
}
.icon-calendar_date_end_checked .path1:before {
  content: "\e93d";
  color: rgb(221, 235, 248);
  opacity: 0.8;
}
.icon-calendar_date_end_checked .path2:before {
  content: "\e93e";
  margin-left: -1.3896484375em;
  color: rgb(192, 220, 247);
  opacity: 0.8;
}
.icon-calendar_date_end_checked .path3:before {
  content: "\e93f";
  margin-left: -1.3896484375em;
  color: rgb(161, 203, 244);
  opacity: 0.8;
}
.icon-calendar_date_end_checked .path4:before {
  content: "\e940";
  margin-left: -1.3896484375em;
  color: rgb(136, 188, 239);
  opacity: 0.8;
}
.icon-calendar_date_start_checked .path1:before {
  content: "\e941";
  color: rgb(221, 235, 248);
  opacity: 0.8;
}
.icon-calendar_date_start_checked .path2:before {
  content: "\e942";
  margin-left: -1.390625em;
  color: rgb(192, 220, 247);
  opacity: 0.8;
}
.icon-calendar_date_start_checked .path3:before {
  content: "\e943";
  margin-left: -1.390625em;
  color: rgb(161, 203, 244);
  opacity: 0.8;
}
.icon-calendar_date_start_checked .path4:before {
  content: "\e944";
  margin-left: -1.390625em;
  color: rgb(136, 188, 239);
  opacity: 0.8;
}
.icon-filter_open:before {
  content: "\e945";
  color: #919ca9;
}
.icon-filter_close:before {
  content: "\e946";
  color: #919ca9;
}
.icon-filter_open_has .path1:before {
  content: "\e947";
  color: rgb(145, 156, 169);
}
.icon-filter_open_has .path2:before {
  content: "\e948";
  margin-left: -1.0322265625em;
  color: rgb(82, 165, 246);
}
.icon-filter_close_has .path1:before {
  content: "\e949";
  color: rgb(145, 156, 169);
}
.icon-filter_close_has .path2:before {
  content: "\e94a";
  margin-left: -1.0322265625em;
  color: rgb(82, 165, 246);
}
.icon-filter_has_hover:before {
  content: "\e94b";
  color: var(--main);
}
.icon-filter_has .path1:before {
  content: "\e94c";
  color: rgb(145, 156, 169);
}
.icon-filter_has .path2:before {
  content: "\e94d";
  margin-left: -1.10546875em;
  color: rgb(82, 165, 246);
}
.icon-str_right:before {
  content: "\e911";
  color: #919ca9;
}
.icon-str_left:before {
  content: "\e912";
  color: #919ca9;
}
.icon-search:before {
  content: "\e913";
  color: #919ca9;
}
.icon-select_point:before {
  content: "\e914";
  color: #c4c8cf;
}
.icon-delete:before {
  content: "\e915";
  color: #919ca9;
}
.icon-refresh:before {
  content: "\e916";
  color: #919ca9;
}
.icon-select_grid_row_2:before {
  content: "\e917";
  color: var(--main);
}
.icon-menu:before {
  content: "\e933";
  color: #fff;
}
.icon-arrow_down:before {
  content: "\e931";
  color: #fff;
}
.icon-forward-long:before {
  content: "\e932";
  color: #bec2cc;
}
.icon-home:before {
  content: "\e930";
  color: var(--main);
}
.icon-404:before {
  content: "\e92e";
  color: var(--main);
}
.icon-logo_title:before {
  content: "\e92f";
}
.icon-admin_logo:before {
  content: "\e91d";
  color: var(--main);
}
.icon-back:before {
  content: "\e91e";
  color: var(--main);
}
.icon-forward:before {
  content: "\e91f";
  color: #a5a9b2;
}
.icon-ok:before {
  content: "\e920";
  color: var(--main);
}
.icon-error:before {
  content: "\e921";
  color: #d36746;
}
.icon-user:before {
  content: "\e922";
  color: var(--main);
}
.icon-support:before {
  content: "\e923";
  color: var(--main);
}
.icon-guide:before {
  content: "\e924";
  color: var(--main);
}
.icon-no_access:before {
  content: "\e925";
  color: var(--main);
}
.icon-info:before {
  content: "\e926";
  color: var(--main);
}
.icon-guide_header:before {
  content: "\e927";
  color: #919ca9;
}
.icon-asuerid_mini:before {
  content: "\e928";
  color: var(--main);
}
.icon-section_mon:before {
  content: "\e929";
  color: var(--main-contrast);
}
.icon-section_nsi:before {
  content: "\e92a";
  color: var(--main-contrast);
}
.icon-section_sysparam:before {
  content: "\e92b";
  color: var(--main-contrast);
}
.icon-section_usr:before {
  content: "\e92c";
  color: var(--main-contrast);
}
.icon-close:before {
  content: "\e92d";
  color: #fff;
}
.icon-exit:before {
  content: "\e900";
  color: #919ca9;
}
.icon-asuerid:before {
  content: "\e901";
  color: var(--main);
}
.icon-unlock:before {
  content: "\e902";
  color: var(--main);
}
.icon-phone:before {
  content: "\e903";
  color: var(--main);
}
.icon-mail:before {
  content: "\e904";
  color: var(--main);
}
.icon-user_logo:before {
  content: "\e905";
  color: #919ca9;
}
.icon-support_header:before {
  content: "\e906";
  color: #919ca9;
}
.icon-hide:before {
  content: "\e907";
  color: #a5a9b2;
}
.icon-show:before {
  content: "\e908";
  color: var(--main);
}
.icon-neighbour_sys:before {
  content: "\e909";
  color: var(--main);
}
.icon-gerb_ministerstva_oborony .path1:before {
  content: "\e90a";
  color: rgb(28, 27, 23);
}
.icon-gerb_ministerstva_oborony .path2:before {
  content: "\e90b";
  margin-left: -1.5419921875em;
  color: rgb(241, 249, 251);
}
.icon-gerb_ministerstva_oborony .path3:before {
  content: "\e90c";
  margin-left: -1.5419921875em;
  color: rgb(227, 30, 36);
}
.icon-gerb_ministerstva_oborony .path4:before {
  content: "\e90d";
  margin-left: -1.5419921875em;
  color: rgb(49, 52, 61);
}
.icon-gerb_ministerstva_oborony .path5:before {
  content: "\e90e";
  margin-left: -1.5419921875em;
  color: rgb(255, 255, 255);
}
.icon-gerb_ministerstva_oborony .path6:before {
  content: "\e90f";
  margin-left: -1.5419921875em;
  color: rgb(223, 232, 234);
}
.icon-gerb_ministerstva_oborony .path7:before {
  content: "\e910";
  margin-left: -1.5419921875em;
  color: rgb(223, 232, 234);
}
.icon-wrong:before {
  content: "\e910";
  color: var(--main);
}
.icon-download_docs:before {
  content: "\e965";
  color: #919ca9;
}
